import { Component } from "react"
import me from "../images/me3.jpeg"
import "./Header.scss"

export default class Header extends Component<any, any> {
	componentDidMount() {
		window.onmousemove = (event: any) => {
			const image = document.getElementById("image-of-myself") as HTMLImageElement
			// tilt image towards mouse
			
		}
	}
	
	render() {
		return <section className={ "header" }>
			<div className={ "image-wrapper" } style={ { position: "relative" } }>
				<img alt={ "An image of myself." } className={ "blur-in iosRounded" } id={ "image-of-myself" }
					 src={ me }
					 style={ { aspectRatio: "1/1", height: "250px", zIndex: "1", perspective: "10px" } }/>
				<div className={ "blur-in iosRounded" }
					 style={ {
						 aspectRatio: "1/1",
						 height: "250px",
						 position: "absolute",
						 zIndex: "0",
						 left: "calc(50% - 105px)",
						 top: "20px",
						 background: "rgba(255, 255, 255, 0.05)"
					 } }/>
			</div>
			
			<div className={ "fade-in" }>
				<p>Hello, my name is</p>
				<p className={ "gradient-text" }>Benni Loidl</p>
				<code className={ "typed-text" }>I'm <span className={ "title-typer" }></span></code>
			</div>
		</section>
	}
}
