import { Component } from "react"
import "./Navigation.scss"

interface NavigationItem {
	name: string,
	id: string
}

interface State {
	navigationItems: NavigationItem[]
}

export default class Navigation extends Component<any, State> {
	constructor(props: any) {
		super(props)
		this.state = {
			navigationItems: []
		}
	}
	
	componentDidMount() {
		document.querySelectorAll(".title").forEach((element: any) => {
			if (this.state.navigationItems.find((item) => item.id === element.id) === undefined) {
				let newNavigationItems = this.state.navigationItems
				newNavigationItems.push({
					name: element.textContent,
					id: element.id
				})
				this.setState({
					navigationItems: newNavigationItems
				})
			}
		})
	}
	
	render() {
		return <nav className={ "navigation" }>
			<div className={ "navigation-menu" } onClick={ () => {
				if (document.querySelector(".navigation")?.classList.contains("active")) {
					document.querySelector(".navigation")?.classList.remove("active")
				}
			} }>
				<div className={ "navigation-items" }>
					{ this.state.navigationItems.map((item, index) => {
						return <a href={ "#" + item.id }
								  id={ item.id + "-link" }
								  className={ "navigation-item" }
								  key={ index }>{ item.name }</a>
					}) }
				</div>
				
				<div className={ "underline" }/>
			</div>
			
			<div className={ "navigation-blob" } onClick={ () => {
				document.querySelector(".navigation")?.classList.toggle("active");
			} }>
				<div className={ "navigation-blob-bar" }/>
				<div className={ "navigation-blob-bar" }/>
				<div className={ "navigation-blob-bar" }/>
			</div>
		</nav>
	}
}
