export default function Section(props: { title: string, caption: string, children: any }) {
	return <div className={ "container" }>
		<section>
			<p className={ "fade-in" } style={ { textAlign: "center" } }>
				<span className={ "caption" }>{ props.caption }</span>
			</p>
			<h1 className={ "title fade-in" } id={ props.title.trim().replaceAll(" ", "-").toLowerCase() }>
				{ props.title }
			</h1>
			
			{ props.children }
		</section>
	</div>
}
