import "./WorkExperience.scss";
import Section from "../components/Section";
import React from "react";
import unic from "../images/unic.png";

export default function WorkExperience() {
	return <Section title={ "Work Experience" } caption={ "My Professional Journey" }>
		<div className={ "work-experience" }>
			<div className={ "fade-in" }>
				<div className={ "work-experience-timeline" }/>
			</div>
			
			<div className={ "work-experience-companies" }>
				<div className={ "work-experience-company fade-in" }>
					<img src={ unic } alt={ "logo of Unic" }/>
					<h2 className={ "heading" }>Unic GmbH</h2>
					<p><span className={ "caption" }>Application Engineer</span></p>
				</div>
			</div>
		</div>
	</Section>
}
