import React, { Component } from "react"
import "./Timeline.scss"
import bmc1 from "../images/bmc1.png"
import bmc2 from "../images/bmc2.png"
import simplechat from "../images/simplechat.png"
import contentful from "../images/contentful-certified-professional.png"
import bigdatadashboard from "../images/bigdatadashboard.png"
import networkSecurity from "../images/network-security.png"

const halfPointHeight = 30

export default class Timeline extends Component<any, any> {
	static updateTimeline = () => {
		// @ts-ignore
		let height = window.scrollY + window.innerHeight * 0.8 - document.querySelector(".timeline")?.offsetTop
		
		if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
			height = document.body.offsetHeight
		} else if (window.scrollY === 0) {
			height = 0
		}
		
		// @ts-ignore
		document.querySelector(".timeline")?.style.setProperty("--timeline-height", `${ height }px`)
		
		document.querySelectorAll(".timeline-item").forEach((item: any) => {
			if (item.offsetTop - halfPointHeight < height) {
				item.classList.add("timeline-item-active")
			} else {
				item.classList.remove("timeline-item-active")
			}
		})
	}
	
	componentDidMount() {
		Timeline.updateTimeline()
	}
	
	render() {
		return <section style={ { paddingBottom: "calc(var(--spacing) * 2)" } }>
			<p className={ "fade-in" } style={ { textAlign: "center" } }>
				<span className={ "caption" }>Continuous Learning</span>
			</p>
			
			<h1 className={ "title fade-in" } id={ "timeline" }>
				Timeline
			</h1>
			
			<div className={ "timeline" }>
				<TimelineItem year={ 2024 } icon={ "fas fa-ethernet" }
							  caption={ "Certification" } title={ "Network Security" }
							  url={ "https://www.credly.com/badges/8e3eb621-e316-4da4-8755-42c3c791ca04/public_url" }>
					<img src={ networkSecurity }
						 alt={ "Network Security Badge" }/>
				</TimelineItem>
				
				<TimelineItem year={ 2024 } icon={ "fas fa-virus-covid" }
							  caption={ "Big Data Project" } title={ "Global COVID-19 Tracker" }
							  url={ "https://bigdatadashboard.loidlnetz.de/" }>
					<img src={ bigdatadashboard }
						 alt={ "Big Data Dashboard" }
						 style={ {
							 width: "100%",
							 borderRadius: "var(--border-radius)"
						 } }/>
				</TimelineItem>
				
				<TimelineItem year={ 2024 } icon={ "fas fa-gamepad" }
							  caption={ "Design Patterns Project" } title={ "FantasyRPG" }>
				</TimelineItem>
				
				<TimelineItem year={ 2024 } icon={ "fas fa-cash-register" }
							  caption={ "Certification" } title={ "Contentful" }
							  url={ "https://www.credly.com/badges/611c69fb-1edd-4c56-92b0-4fff6540ae43/public_url" }>
					<img src={ contentful }
						 alt={ "Contentful Certified Professional Badge" }/>
				</TimelineItem>
				
				<TimelineItem year={ 2023 } icon={ "fas fa-comments" }
							  caption={ "Project" } title={ "simpleQ" }
							  url={ "https://github.com/benniloidl/simpleq" }>
					<div className={ "card" }>
						<p className={ "paragraph" }>
							<i className={ "fas fa-hourglass-start" }></i>
							Coming soon..
						</p>
					</div>
				</TimelineItem>
				
				<TimelineItem year={ 2023 } icon={ "fas fa-comment-dots" }
							  caption={ "Project" } title={ "SimpleChat" }
							  url={ "https://github.com/benniloidl/simplechat" }>
					<img src={ simplechat }
						 alt={ "SimpleChat" }
						 style={ {
							 width: "100%",
							 borderRadius: "var(--border-radius)",
							 filter: "contrast(1)"
						 } }/>
					
					<div className={ "card" }>
						<p className={ "paragraph" }>
							<i className={ "fas fa-people-group" }></i>
							Team of 4 people
						</p>
					</div>
				</TimelineItem>
				
				<TimelineItem year={ "2018- 2020" } icon={ "fas fa-cube" }
							  caption={ "Minecraft Network" } title={ "BenjoMC" }>
					<img src={ bmc2 }
						 alt={ "Minecraft" }
						 style={ { width: "100%", borderRadius: "var(--border-radius)" } }/>
					
					<div className={ "card" }>
						<p className={ "paragraph" }>
							<i className={ "fas fa-chalkboard-user" }></i>
							Lead of the entire network
						</p>
					</div>
				</TimelineItem>
				
				<TimelineItem year={ 2018 } icon={ "fas fa-trophy" }
							  caption={ "Achievement" } title={ "80+ Players" }>
					<iframe src="https://www.youtube-nocookie.com/embed/MC6BZG7PwCQ"
							title="YouTube video player"
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowFullScreen
							style={ {
								width: "100%",
								aspectRatio: "16 / 9",
								borderRadius: "var(--border-radius)"
							} }></iframe>
					
					<div className={ "card" }>
						<p className={ "paragraph" }>
							<i className={ "fas fa-rocket" }></i>
							Increased player count by 800%, hitting 80+ players, limited by server hardware
						</p>
						
						<hr/>
						
						<p className={ "paragraph" }>
							<i className={ "fas fa-plug-circle-xmark" }></i>
							Performance issues due to bad server hardware & DDoS attacks
						</p>
					</div>
				</TimelineItem>
				
				<TimelineItem year={ 2018 } icon={ "fas fa-hand-holding-dollar" }
							  caption={ "Achievement" } title={ "First Income" }>
					<div className={ "card" }>
						<p className={ "paragraph" }>
							<i className={ "fas fa-calendar-plus" }></i>
							December 2017
						</p>
						
						<hr/>
						
						<p className={ "paragraph" }>
							<i className={ "fas fa-turn-up" }></i>
							Increased server budget & performance
						</p>
					</div>
				</TimelineItem>
				
				<TimelineItem year={ 2017 } icon={ "fas fa-network-wired" }
							  caption={ "Minecraft Network" } title={ "Your-Game Group" }>
					<img src={ bmc1 }
						 alt={ "Minecraft" }
						 style={ {
							 width: "100%",
							 borderRadius: "var(--border-radius)",
							 filter: "contrast(1.2)"
						 } }/>
					
					<div className={ "card" }>
						<p className={ "paragraph" }>
							<i className={ "fas fa-chalkboard-user" }></i>
							Lead of the entire network
						</p>
						
						<hr/>
						
						<p className={ "paragraph" }>
							<i className={ "fas fa-people-group" }></i>
							Team of 15 people
						</p>
						
						<hr/>
						
						<p className={ "paragraph" }>
							<i className={ "fas fa-code" }></i>
							100% custom coded, 30k+ lines of code
						</p>
						
						<hr/>
						
						<p className={ "paragraph" }>
							<i className={ "fa-brands fa-teamspeak" }></i>
							TeamSpeak server, bots & integrations
						</p>
						
						<hr/>
						
						<p className={ "paragraph" }>
							<i className={ "fas fa-chart-simple" }></i>
							Frontend for visualizing player stats
						</p>
					</div>
				</TimelineItem>
				
				<TimelineItem year={ 2018 } icon={ "fa-brands fa-youtube" }
							  caption={ "Achievement" } title={ "YouTube Presence" }>
					<div className={ "card" }>
						<p className={ "paragraph" }>
							<i className={ "fas fa-tower-broadcast" }></i>
							Meeting new people live streaming their experience on my server
						</p>
						
						<hr/>
						
						<p className={ "paragraph" }>
							<i className={ "fas fa-church" }></i>
							Engaging with the community
						</p>
					</div>
				</TimelineItem>
				
				<TimelineItem year={ 2013 } icon={ "fas fa-server" }
							  caption={ "Minecraft Server" } title={ "Your-Game" }>
					<div className={ "card" }>
						<p className={ "paragraph" }>
							<i className={ "fas fa-code" }></i>
							First experience with coding
						</p>
						
						<hr/>
						
						<p className={ "paragraph" }>
							<i className={ "fas fa-bullhorn" }></i>
							First experience with building a team
						</p>
					</div>
				</TimelineItem>
			</div>
		</section>
	}
}

function TimelineItem(props: {
	year: number | string,
	icon: string,
	caption: string,
	title: string,
	url?: string,
	children?: any
}) {
	return <div className={ "timeline-item" }>
		<div className={ "timeline-point" }>{ props.year }</div>
		<div className={ "timeline-content" }>
			<h2 className={ "heading" }>
				<span className={ "caption" }>{ props.caption }</span>
				<span style={ { display: "inline-flex", lineHeight: "40px" } }>
					<i className={ props.icon }/>
					{ props.url ? <a href={ props.url }
									 rel={ "noreferrer" }
									 target={ "_blank" }>
						{ props.title }
						<i className={ "fas fa-arrow-up-right-from-square" }/>
					</a> : props.title }
				</span>
			</h2>
			
			{ props.children }
		</div>
	</div>
}
