import "./Education.scss";
import Section from "../components/Section";
import React from "react";
import lmg from "../images/lmg.png";
import dhbw from "../images/dhbw.png";
import xamk from "../images/xamk.png";

const education: {
	name: string,
	logo: string,
	date: string,
	location: string,
	description: any[],
	links?: string[],
	hashtags: string[]
}[] = [
	{
		name: "Ludwig-Marum-Gymnasium",
		logo: lmg,
		date: "2014 - 2022",
		location: "Pfinztal, DE",
		description: ["I attended the Ludwig-Marum-Gymnasium in Pfinztal, Germany. I graduated in 2022 with an Abitur. In the IT class (Informatik), I improved my skills in Java and scored 15 points (the highest possible grade) in the final exam."],
		hashtags: ["abitur", "graduation", "best in class"]
	},
	{
		name: "DHBW",
		logo: dhbw,
		date: "2022 - Now",
		location: "Karlsruhe, DE",
		description: ["I am currently attending the DHBW in Karlsruhe, Germany, where I am studying computer science (Informatik). During my studies, I have learned a lot about programming, web engineering, and software engineering. In the past semesters, I have worked on several group projects, where we developed software together. These projects helped me improve my programming skills and taught me how to work in a team. Through my studies, I have gained a better understanding of how software is designed, developed, and tested."],
		links: ["SimpleChat", "simpleQ"],
		hashtags: ["computer science", "software engineering", "web engineering"]
	},
	{
		name: "South-Eastern Finland University of Applied Sciences",
		logo: xamk,
		date: "Autumn 2024",
		location: "Mikkeli, FI",
		description: [
			"As part of my studies at the DHBW, I spent a semester at the South-Eastern Finland University of Applied Sciences in Mikkeli, Finland. I studied computer science, participating in the following courses: ",
			<ul>
				<li>Design patterns and object-oriented techniques (96%)</li>
				<li>Data center environments (98%)</li>
				<li>Information and network security (97%)</li>
				<li>Big data and visualization (80%)</li>
			</ul>
		],
		links: ["Global COVID-19 Tracker", "FantasyRPG"],
		hashtags: ["exchange semester abroad", "finland"]
	}
];

export default function Education() {
	return <Section title={ "Education" } caption={ "What I have Studied so far" }>
		<div className={ "education" }>
			{ education.map((item, index) => {
				return <div className={ "education-tile fade-in" } key={ index }>
					<div className={ "education-tile-logo" }>
						<img src={ item.logo }
							 alt={ "logo of " + item.name }/>
					</div>
					<div className={ "education-tile-content" }>
						<h2 className={ "heading" }>{ item.name }</h2>
						<div className={ "caption-row" }>
							<p className={ "caption" }>{ item.date }</p>
							<p className={ "caption" }>
								<i className={ "fas fa-map-marker-alt" }/>
								{ " " }
								{ item.location }
							</p>
						</div>
						
						<div>{ item.description.map((item, index) => (
							(typeof item === "string")
								? <p key={ item }>{ item }</p>
								: item
						)) }</div>
						
						{ item.links && <div className={ "education-tile-links" }>
							{ item.links.map((link) => <a key={ link } href={ "#" }>
								<i className={ "fas fa-arrow-up-right-from-square" }/>
								{ link }
							</a>) }
                        </div> }
						
						<p className={ "education-tile-hashtags" }>
							{ item.hashtags.map((hashtag) => <span key={ hashtag }>
								#{ hashtag.trim().replaceAll(" ", "-").toLowerCase() }
							</span>) }
						</p>
					</div>
				</div>
			}) }
		</div>
	</Section>
}
