import React, { Component } from 'react'
import Timeline from "./app/Timeline"
import Header from "./app/Header"
import Social from "./app/Social"
import Loader from "./app/Loader"
import Skills from "./app/Skills"
import Navigation from "./app/Navigation"
import Education from "./app/Education";
import WorkExperience from "./app/WorkExperience";

import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";

export default class App extends Component<any, any> {
	
	typeTitle = () => {
		const element = document.querySelector(".title-typer");
		if (!element) return;
		
		const titles = [
			"a Developer.",
			"a Student.",
			"a Skier.",
			this.getAge() + " years old.",
			"always right."
		];
		const waitWhenBlank = 2;
		const waitWhenFinished = 4;
		
		let titleIndex = 0;
		let currentCount = 0;
		let reversing = false;
		setInterval(() => {
			if (reversing) {
				currentCount--;
				if (currentCount <= 0 - waitWhenBlank) {
					reversing = false;
					titleIndex++;
					if (titleIndex >= titles.length) titleIndex = 0;
				}
			} else {
				currentCount++;
				if (currentCount >= titles[titleIndex].length + waitWhenFinished) {
					reversing = true;
				}
			}
			element.innerHTML = titles[titleIndex].substring(0, currentCount);
		}, 250);
	}
	
	getAge = () => {
		const birthday = new Date(2004, 3, 25);
		// @ts-ignore
		return Math.floor((new Date() - birthday) / 1000 / 60 / 60 / 24 / 365);
	}
	
	componentDidMount() {
		this.typeTitle();
		
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) entry.target.classList.add("show")
				else entry.target.classList.remove("show")
			})
		})
		const elements = document.querySelectorAll(".blur-in, .fade-in, .skill-progress")
		elements.forEach(e => observer.observe(e))
		
		Timeline.updateTimeline()
		this.updateNavigation()
		
		const loader = document.querySelector(".loader")
		if (loader) { // @ts-ignore
			loader.style.visibility = "hidden"
		}
		
		window.onscroll = () => {
			Timeline.updateTimeline()
			this.updateNavigation()
		}
		
		window.onresize = () => {
			Timeline.updateTimeline()
			this.updateNavigation()
		}
	}
	
	updateNavigation = () => {
		const headings = document.querySelectorAll(".title")
		
		console.log("updating navigation")
		
		while (headings.length === 0) console.log("waiting for headings")
		
		
		let lastHeadingId = headings[0].id
		for (let i = 0; i < headings.length; i++) {
			const heading = headings[i]
			if (heading.getBoundingClientRect().y < window.innerHeight / 2)
				lastHeadingId = heading.id
		}
		
		const navigationUnderline = document.querySelector("nav.navigation .underline")
		if (navigationUnderline) {
			let rect = document.querySelector("nav.navigation a#" + lastHeadingId + "-link")?.getBoundingClientRect()
			if (!rect) return
			let offsetLeft = rect.x
			
			// @ts-ignore
			navigationUnderline.style.opacity = "1"
			// @ts-ignore
			navigationUnderline.style.left = offsetLeft - 2 + "px"
			// @ts-ignore
			navigationUnderline.style.width = rect.width + 4 + "px"
			// @ts-ignore
			navigationUnderline.style.height = rect.height + 4 + "px"
		}
	}
	
	render() {
		return <main>
			<Loader/>
			
			<Navigation/>
			
			<div className={ "container" }>
				<Header/>
			</div>
			
			<hr/>
			
			<div className={ "container" }>
				<Skills/>
			</div>
			
			<hr/>
			
			<div className={ "container" }>
				<Social/>
			</div>
			
			<hr/>
			
			<Education/>
			
			<hr/>
			
			<WorkExperience/>
			
			<hr/>
			
			<div className={ "container" }>
				<Timeline/>
			</div>
			
			<hr/>
			
			<div className={ "container" }>
				<section>
					<p className={ "fade-in" } style={ { margin: 0 } }>&copy; loidlNetz { new Date().getFullYear() }</p>
				</section>
			</div>
			
			<Analytics/>
			<SpeedInsights/>
		</main>
	}
}
