import React, { Component } from "react"
import "./Social.scss"

export default class Social extends Component<any, any> {
	render() {
		return <>
			<section>
				<p className={ "fade-in" } style={ { textAlign: "center" } }>
					<span className={ "caption" }>Let's get in touch!</span>
				</p>
				<h1 className={ "title fade-in" } id={ "social" }>
					Social
				</h1>
			</section>
			
			<section className={ "social-grid" }>
				<a className={ "social-link fade-in" }
				   href={ "https://www.linkedin.com/in/benjaminloidl/" }
				   target={ "_blank" }
				   rel="noreferrer">
					<i className={ "fa-brands fa-2x fa-linkedin" }></i>
					<p>
						<span style={ { fontWeight: "800" } }>LinkedIn</span>
						<br/>
						<span className={ "subtitle" }>Benjamin Loidl</span>
					</p>
				</a>
				<a className={ "social-link fade-in" }>
					<i className={ "fa-brands fa-2x fa-discord" }></i>
					<p>
						<span style={ { fontWeight: "800" } }>Discord</span>
						<br/>
						<span className={ "subtitle" }>altetaube</span>
					</p>
				</a>
				<a className={ "social-link fade-in" }
				   href={ "https://github.com/altetaube" }
				   target={ "_blank" }
				   rel="noreferrer">
					<i className={ "fa-brands fa-2x fa-github" }></i>
					<p>
						<span style={ { fontWeight: "800" } }>GitHub</span>
						<br/>
						<span className={ "subtitle" }>@benniloidl</span>
					</p>
				</a>
				<a className={ "social-link fade-in" }
				   href={ "https://youtube.com/@altetaube" }
				   target={ "_blank" }
				   rel="noreferrer">
					<i className={ "fa-brands fa-2x fa-youtube" }></i>
					<p>
						<span style={ { fontWeight: "800" } }>YouTube</span>
						<br/>
						<span className={ "subtitle" }>@altetaube</span>
					</p>
				</a>
				<a className={ "social-link fade-in" }
				   href={ "mailto:mail@loidlnetz.de" }>
					<i className={ "fas fa-2x fa-envelope" }></i>
					<p>
						<span style={ { fontWeight: "800" } }>E-Mail</span>
						<br/>
						<span className={ "subtitle" }>mail@loidlnetz.de</span>
					</p>
				</a>
			</section>
		</>
	}
}
